import React, {useEffect, useRef, useState} from "react";
import {Text} from "@mantine/core";
import {useReactToPrint} from "react-to-print";
import dayjs from "dayjs";

//API
import {ITicket, useLazyReadTicketQuery} from "../../../api/event";

import classes from "./orders.module.css";

const PrintTicket = ({ticket, tickets, onAfterPrint}: { ticket?: any, tickets?: any[], onAfterPrint?: () => void }) => {
    const ticketRef = useRef(null);
    const [getTicket] = useLazyReadTicketQuery();
    const [ticketValue, setTicketValue] = useState<ITicket | null>(null)
    const handlePrint = useReactToPrint({
        content: () => ticketRef.current,
        copyStyles: true,
        pageStyle: `@media print {
            @page {
                size: 13.9cm 6.5cm !important;
                margin: 0;
            }
        }`,
        onAfterPrint: () => {
            setTicketValue(null);
            onAfterPrint && onAfterPrint();
        }
    });

    useEffect(() => {
        if (ticket && ticket.id) {
            getTicket({id: ticket.id}).unwrap().then(res => {
                setTicketValue(res)
                setTimeout(() => {
                    handlePrint();
                }, 500)
            }).catch(err => {
                console.log('read ticket error', err)
            })

        }
    }, [ticket?.id])

    useEffect(() => {
        if(tickets && tickets.length > 0) {
            setTicketValue(tickets[0])
            setTimeout(() => {
                handlePrint();
            }, 500)
        }
    }, [tickets?.length]);

    if (!ticketValue) return <></>
    // const [place, row, seat] = ticket.key.split(":");
    if (tickets) {
        return (
            <div ref={ticketRef}>
                {tickets.map(ticketValue => {
                    return (
                        <div className={classes.ticketPrint} key={ticketValue.key}>
                            <div className={classes.qr}>
                                <img src={import.meta.env.VITE_BACKEND_URL + ticketValue.qrcode} alt={"qr"}
                                     width={60} height={60}
                                />
                            </div>
                            <div>
                                <div>
                                    <Text fw={'bold'}>{ticketValue.event?.name.toUpperCase()}</Text>
                                </div>

                                <div className={classes.rowItem}>
                                    <Text fw={'bold'}>Data</Text>
                                    <Text>{dayjs(ticketValue.event?.start).format('DD MM YYYY')}</Text>
                                </div>

                                <div className={classes.rowItem}>
                                    <Text fw={'bold'}>Ora</Text>
                                    <Text>{dayjs(ticketValue.event?.start).format('HH:mm')}</Text>
                                </div>

                                <div className={classes.rowItem}>
                                    <Text fw={'bold'}>Rindul</Text>
                                    <Text>{ticketValue.key[1]}</Text>
                                </div>

                                <div className={classes.rowItem}>
                                    <Text fw={'bold'}>Locul</Text>
                                    <Text>{ticketValue.key[2]}</Text>
                                </div>
                                <div className={classes.rowItem}>
                                    <Text fw={'bold'}>Pretul</Text>
                                    <Text>{ticketValue.amount}</Text>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
    return (
        <div ref={ticketRef} className={classes.ticketPrint}>
            <div className={classes.qr}>
                <img src={import.meta.env.VITE_BACKEND_URL + ticketValue.qrcode} alt={"qr"}
                     width={60} height={60}
                />
            </div>
            <div>
                <div>
                    <Text fw={'bold'}>{ticketValue.event.name.toUpperCase()}</Text>
                </div>

                <div className={classes.rowItem}>
                    <Text fw={'bold'}>Data</Text>
                    <Text>{dayjs(ticketValue.event.start).format('DD MM YYYY')}</Text>
                </div>

                <div className={classes.rowItem}>
                    <Text fw={'bold'}>Ora</Text>
                    <Text>{dayjs(ticketValue.event.start).format('HH:mm')}</Text>
                </div>

                <div className={classes.rowItem}>
                    <Text fw={'bold'}>Rindul</Text>
                    <Text>{ticket.key[1]}</Text>
                </div>

                <div className={classes.rowItem}>
                    <Text fw={'bold'}>Locul</Text>
                    <Text>{ticket.key[2]}</Text>
                </div>
                <div className={classes.rowItem}>
                    <Text fw={'bold'}>Pretul</Text>
                    <Text>{ticketValue.amount}</Text>
                </div>
            </div>
        </div>
    )
}

export default PrintTicket;